import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cueDragScroll]',
  standalone: true,
})
export class DragScrollDirective {
  private isDown = false;
  private startY = 0;
  private scrollTop = 0;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.el.nativeElement.style.cursor = 'grab';
    this.el.nativeElement.style.overflowY = 'auto';

    this.renderer.listen('document', 'mouseup', () => this.onMouseUp());
    this.renderer.listen('document', 'mousemove', (event) => this.onMouseMove(event));
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.isDown = true;
    this.startY = event.pageY - this.el.nativeElement.offsetTop;
    this.scrollTop = this.el.nativeElement.scrollTop;
    this.el.nativeElement.style.cursor = 'grabbing';
    this.el.nativeElement.style.userSelect = 'none';
  }

  onMouseUp(): void {
    this.isDown = false;
    this.el.nativeElement.style.cursor = 'grab';
    this.el.nativeElement.style.userSelect = '';
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDown) return;
    event.preventDefault();
    const y = event.pageY - this.el.nativeElement.offsetTop;
    const walk = (y - this.startY) * 2;
    this.el.nativeElement.scrollTop = this.scrollTop - walk;
  }
}
